import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Enviroment
import { environment } from '@env';
// Models
import { CoreModels, CustomerModels } from 'src/core/models/index';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private URL_BASE = `https://${environment.apiUrl}/credit`;

  // Customer

  public getCustomer(queryParams: CoreModels.Params): Observable<CustomerModels.DataCustomer> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      ),
    };

    let url: string = `https://${environment.apiUrl}/credit/customer`;

    return this.http.get<CustomerModels.DataCustomer>(url, OPTIONS);
  }

  public getCustomerById(id: number): Observable<CustomerModels.Customer> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      ),
    };

    const URL: string = `${this.URL_BASE}/customer/${id}`;

    return this.http.get<CustomerModels.Customer>(URL, OPTIONS);
  }

  // Customer Address

  public getCustomerAddressById(customerId: number): Observable<CustomerModels.Address> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      ),
    };

    const URL = `${this.URL_BASE}/customer/address/${customerId}`;

    return this.http.get<CustomerModels.Address>(URL, OPTIONS);
  }

  // Guarantee

  public getGuaranteeById(customerId: number): Observable<CustomerModels.Guarantee> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      ),
    };

    const URL: string = `${this.URL_BASE}/guarantee/${customerId}`;

    return this.http.get<CustomerModels.Guarantee>(URL, OPTIONS);
  }

  // Guarantee Address

  public getGuaranteeAddressById(guaranteeId: number): Observable<CustomerModels.GuaranteeAddress> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      ),
    };

    let url: string = `https://${environment.apiUrl}/credit/guarantee/address/${guaranteeId}`;

    return this.http.get<CustomerModels.GuaranteeAddress>(url, OPTIONS);
  }

  constructor(
    private http: HttpClient
  ) { }

}
