import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Enviroment
import { environment } from 'src/environments/environment';
// Models
import { CoreModels, CreditModels, ReportModels } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private URL_PDF = `https://${environment.apiReport}/report`;
  private URL_REPORT = `https://${environment.apiUrl}/report`;

  public getContract(data: CreditModels.CreditDisbursement): Observable<any> {
    const URL = `${this.URL_PDF}/contract`;

    return this.http.post(URL, data, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
    });
  }

  public getReportTrialBalance(
    params?: CoreModels.Params
  ): Observable<ReportModels.TrialBalance[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
        }`,
      }),
      params: new HttpParams().set(
        'zone_id',
        params?.zone_id === 'all' ? '' : params?.zone_id || ''
      ),
    };

    const URL = `${this.URL_REPORT}/trial/balance`;
    return this.http.get<ReportModels.TrialBalance[]>(URL, OPTIONS);
  }

  public getCreditCollectionSummary(params: CoreModels.ParamCreditCollectionSummary) : Observable<ReportModels.CreditCollectionSummary[]>{
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
        }`,
      }),
      params: new HttpParams()
        .set('start_date', !params.start_date.length ? '' : params.start_date)
        .set('end_date', !params.end_date.length ? '' : params.end_date)  
        .set('zone_id', params.zone_id === 'all' ? '' : params.zone_id)
    };

    const URL = `${this.URL_REPORT}/credit/collection/summary`;
    return this.http.get<ReportModels.CreditCollectionSummary[]>(URL, OPTIONS);
  }

  public getCreditCustomerDetail(params: CoreModels.ParamCreditCollectionSummary) : Observable<ReportModels.ResponseCreditCustomerDetail>{
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
        }`,
      }),
      params: new HttpParams()
        .set('start_date', !params.start_date.length ? '' : params.start_date)
        .set('end_date', !params.end_date.length ? '' : params.end_date)  
        .set('zone_id', params.zone_id === 'all' ? '' : params.zone_id)
    };

    const URL = `${this.URL_REPORT}/credit/customer/detail`;
    return this.http.get<ReportModels.ResponseCreditCustomerDetail>(URL, OPTIONS);
  }

  constructor(private http: HttpClient) {}
}
