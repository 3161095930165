import { createReducer, on } from '@ngrx/store';
import { UserActions } from '@state/actions';
import { UserState } from './state';

export const initialState: UserState = {
    error: '',
    isLoading: false,
};

export const UserReducers = createReducer(
    initialState,
    // ERROR
    on(UserActions.error, (state, { error }) => ({ ...state, error: error, isLoading: false })),
    // INIT
    on(UserActions.initFromPayload, (state, { payload }) => ({ 
        ...state, 
        user_id: payload.id,  
        zone_id: payload.zone_id, 
        first_name: payload.first_name, 
        last_name: payload.last_name, 
        phone: payload.phone, 
        username: payload.username, 
        isLoading: true 
    })),
    // ROLE
    on(UserActions.roleSuccess, (state, { role }) => ({ 
        ...state, 
        role_data: role,
        isLoading: false
    })),
)