import { createAction, props } from "@ngrx/store";
import { CoreModels } from "src/core/models";

// ERROR
export const error = createAction("[Auth] Error",props<{ error: string }>());
// VALIDATE SESSION
export const validate = createAction('[Auth] Validate');
export const validateSuccess = createAction('[Auth] Validate Success', props<{ payload: CoreModels.JWTPayload }>());
// LOGIN
export const login = createAction('[Auth] Login', props<{ username: string, password: string, remember: boolean, recaptcha: string}>());
export const loginSuccess = createAction('[Auth] Login Success', props<{ payload: CoreModels.JWTPayload }>());
// REFRESH
export const refreshSuccess = createAction('[Auth] Refresh Success',props<{ payload: CoreModels.JWTPayload }>());
// DISPLAY OTP WINDOW
export const displayOtp = createAction('[Auth] Display OTP');
// SET OTP
export const setOtp = createAction('[Auth] Set OTP', props<{ token: string | null }>());
// CLEAR OTP
export const clearOtp = createAction('[Auth] Clear OTP');