import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, map } from 'rxjs/operators';
import * as MenuActions from './actions';

@Injectable()
export class MenuEffects {
  open$ = createEffect(() => this.actions$.pipe(
    ofType(MenuActions.openMenu),
    delay(10),
    map(() => {
      return MenuActions.displayMenu()
    })
  ));

  close$ = createEffect(() => this.actions$.pipe(
    ofType(MenuActions.closeMenu),
    delay(300),
    map(() => {
      return MenuActions.hiddeMenu()
    })
  ));

  constructor(
      private actions$: Actions,
  ) { }
}

//delay(10)
//return MenuActions.displayMenu({ display: true })
