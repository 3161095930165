import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
// Enviroment
import { environment } from '@env';
// Models
import { CreditModels, SystemModels, CoreModels } from 'src/core/models/index';
import { CreditDisbursement } from 'src/core/models/credit/credit_disbursement.model';


@Injectable({
  providedIn: 'root',
})
export class CreditService {
  private URL_BASE = `https://${environment.apiUrl}/credit`;

  // Credit Request

  public addCreditRequest(creditRequest: CreditModels.CreditRequest): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      ),
    };

    const URL = `${this.URL_BASE}/request`;
    return this.http.post<SystemModels.Validation>(URL, creditRequest, OPTIONS);
  }

  public getCreditRequest(params?: CoreModels.Params): Observable<CreditModels.RequestList> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
      params: new HttpParams()
        .set('zone_id', params?.zone_id === 'all' ? '' : params?.zone_id || '')
        .set('status', params?.status || '')
        .set('user_id', params?.user_id === 'all' ? '' : params?.user_id || '')
        .set('limit', params?.limit || 10)
        .set('offset', params?.offset || 1)
    };

    const URL = `${this.URL_BASE}/request`;

    return this.http.get<CreditModels.RequestList>(URL, OPTIONS);
    // .pipe(tap(res => console.log(res)));
  }

  public updateCreditRequest(id: number, data: CreditModels.RequestAdvanced): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/request/${id}`;
    return this.http.put<SystemModels.Validation>(URL, data, OPTIONS);
  }

  public getCreditRequestById(
    id: string
  ): Observable<CreditModels.CreditRequest> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/request/${id}`;
    return this.http.get<CreditModels.CreditRequest>(URL, OPTIONS);
  }

  public deleteCreditRequest(id: string): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/request/${id}`;
    return this.http.get<SystemModels.Validation>(URL, OPTIONS);
  }

  // Customer

  public getCreditRequestCustomerById(
    customerId: number
  ): Observable<CreditModels.CustomerWork> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/request/customer/${customerId}`;
    return this.http.get<CreditModels.CustomerWork>(URL, OPTIONS);
  }

  // Guarantee

  public getCreditRequestGuaranteeById(
    guaranteeId: number
  ): Observable<CreditModels.GuaranteeWork> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/request/guarantee/${guaranteeId}`;
    return this.http.get<CreditModels.GuaranteeWork>(URL, OPTIONS);
  }

  // Transaction Status

  public getTransactionStatusByType(type: number): Observable<CreditModels.TransactionStatus[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/transaction/status/type/${type}`;
    return this.http.get<CreditModels.TransactionStatus[]>(URL, OPTIONS);
  }

  // Payment Term

  public getPaymentTerm(): Observable<CreditModels.PaymentTerm[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/term`;
    return this.http.get<CreditModels.PaymentTerm[]>(URL, OPTIONS);
  }

  // Interest Rate

  public getInterestRate(): Observable<CreditModels.InterestRate[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/interest`;
    return this.http.get<CreditModels.InterestRate[]>(URL, OPTIONS);
  }

  // Commission

  public getTypeCommission(): Observable<CreditModels.TypeCommission[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/commission/type`;
    return this.http.get<CreditModels.TypeCommission[]>(URL, OPTIONS);
  }

  public addCommission(data: CreditModels.AddCommission) {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/commission`;
    return this.http.post<SystemModels.Validation>(URL, data, OPTIONS);
  }

  public getCommission(): Observable<CreditModels.Commission[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/commission`;
    return this.http.get<CreditModels.Commission[]>(URL, OPTIONS);
  }

  public getCommissionUser(): Observable<CreditModels.CommissionUser[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/commission/user`;
    return this.http.get<CreditModels.CommissionUser[]>(URL, OPTIONS);
  }

  public getCommissionConfigById(commissionId: number): Observable<CreditModels.CommissionConfig[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/commission/config/${commissionId}`;
    return this.http.get<CreditModels.CommissionConfig[]>(URL, OPTIONS);
  }

  public getCommissionOption(): Observable<CreditModels.CommissionOption[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/commission/option`;
    return this.http.get<CreditModels.CommissionOption[]>(URL, OPTIONS);
  }

  public getCommissionVariant(): Observable<CreditModels.CommissionVariant[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/commission/variant`;
    return this.http.get<CreditModels.CommissionVariant[]>(URL, OPTIONS);
  }


  // Transaction

  public getTransaction(params?: CoreModels.Params): Observable<CreditModels.ResponseTransactionList> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      ),
      params: new HttpParams()
        .set('zone_id', params?.zone_id === 'all' ? '' : params?.zone_id || '')
        .set('search', params?.search === null || params?.search === undefined ? '' : params?.search)
        .set('limit', params?.limit || 5)
        .set('offset', params?.offset || 1)

    };
    const URL = `${this.URL_BASE}/transaction`;
    return this.http.get<CreditModels.ResponseTransactionList>(URL, OPTIONS);
  }

  // Payment

  public addPayment(payments: CreditModels.PaymentProcessing[]): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      ),
    };

    const URL = `${this.URL_BASE}/payment`;
    return this.http.post<SystemModels.Validation>(URL, payments, OPTIONS);
  }

  public getPaymentCollection(params?: CoreModels.Params): Observable<CreditModels.ResponsePaymentCollect> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      ),
      params: new HttpParams()
        .set('user_id', params?.user_id === 'all' ? '' : params?.user_id || '')
        .set('date', params?.date || '')
    };
    const URL = `${this.URL_BASE}/payment/collect`;
    return this.http.get<CreditModels.ResponsePaymentCollect>(URL, OPTIONS);
  }

  public getPaymentRecover(params?: CoreModels.Params): Observable<CreditModels.ResponsePaymentRecover> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      ),
      params: new HttpParams()
        .set('user_id', params?.user_id === 'all' ? '' : params?.user_id || '')
    };
    const URL = `${this.URL_BASE}/payment/recovery`;
    return this.http.get<CreditModels.ResponsePaymentRecover>(URL, OPTIONS);
  }

  // Upload Files

  public uploadIdentification(data: CreditModels.UploadFile): Observable<CreditModels.ResponseUploadFile> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`,
      })
    };

    const URL = `${this.URL_BASE}/upload/identification`;
    return this.http.put<CreditModels.ResponseUploadFile>(URL, data, OPTIONS);
  }

  // Disbursement

  public getCreditDisbursement(params?: CoreModels.Params): Observable<CreditModels.ResponseCreditDisbursement> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      ),
      params: new HttpParams()
        .set('zone_id', params?.zone_id === 'all' ? '' : params?.zone_id || '')
        .set('date', params?.date || '')
    };

    const URL = `${this.URL_BASE}/disbursement`;
    return this.http.get<CreditModels.ResponseCreditDisbursement>(URL, OPTIONS);
  }

  public updateCreditDisbursement(data: CreditDisbursement[]): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      )
    };

    const URL = `${this.URL_BASE}/disbursement`;
    return this.http.put<SystemModels.Validation>(URL, data, OPTIONS);
  }

  public cancelCredit(id: number): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')}`
        }
      )
    };

    const URL = `${this.URL_BASE}/transaction/cancel/${id}`;
    return this.http.put<SystemModels.Validation>(URL, null, OPTIONS);
  }


  constructor(private http: HttpClient) { }
}
