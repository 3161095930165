import { ActionReducerMap } from "@ngrx/store";
import { CoreState } from "./core.state";

import { MenuReducers } from "./menu/reducers";
import { AuthReducers } from './auth/reducers';
import { UserReducers } from "./user/reducers";

export const CoreReducers: ActionReducerMap<CoreState> = {
  menu: MenuReducers,
  auth: AuthReducers,
  user: UserReducers,
}
