import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserActions } from '@state/actions';
import { of } from 'rxjs';
import { map, mergeMap, catchError,  } from 'rxjs/operators';
// Services
import { SystemService } from '@services';

@Injectable()
export class UserEffects {
    initFromPayload$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.initFromPayload),
        mergeMap(({ payload }) => this.$system.getRoleById(payload.role_id).pipe(
            map(role => {
                role.permission_web = role.permission_web.sort((a, b) => {
                  return a.sort - b.sort;
                });
                return UserActions.roleSuccess({ role: role })
            }),
            catchError((err) => of(UserActions.error({error: err.message})))
          ))
        )
    );

    constructor(
        private actions$: Actions,
        private $system: SystemService
    ) { }
}
