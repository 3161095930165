import { createSelector } from '@ngrx/store';
import { CoreState } from '../core.state';
import { UserState } from './state';

export const selectUser = (state: CoreState) => state.user;

export const selectPermissionsWeb = createSelector(
    selectUser,
    (state: UserState) => state.role_data?.permission_web
);

export const selectPermissionsApi = createSelector(
    selectUser,
    (state: UserState) => state.role_data?.permission_api
);