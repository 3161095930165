import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
// Routing
import { AppRouter } from './app.routes';
// Main Component
import { AppComponent } from './app.component';


import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { CoreReducers } from '@state/reducers';
import { CoreEffects } from '@state/effects';
import { InlineSVGModule } from 'ng-inline-svg-2';


import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';



registerLocaleData(localeEsMx, 'es-MX');

@NgModule({
    declarations: [
        AppComponent,  
    ],
    providers: [],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRouter,
        HttpClientModule,
        InlineSVGModule.forRoot(),
        //NGRX STORE
        StoreModule.forRoot(CoreReducers),
        EffectsModule.forRoot(CoreEffects),
        //NGRX DEVTOOLS
        StoreDevtoolsModule.instrument({})
    ]
})
export class AppModule { }
