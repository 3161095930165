import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './actions';
import { AuthState } from './state';

export const initialState: AuthState = {
    error: '',
    isLoading: false,
    displayOtp: false,
    tokenExpiration: 0,
    tokenOtp: null
};

export const AuthReducers = createReducer(
    initialState,
    // ERROR
    on(AuthActions.error, (state, { error }) => ({ ...state, error: error, tokenExpiration: 0, isLoading: false })),
    // VALIDATE SESSION
    on(AuthActions.validate, (state, {}) => ({ ...state, isLoading: true })),
    on(AuthActions.validateSuccess, (state, { payload }) => ({ ...state, tokenExpiration: payload.exp, isLoading: false })),
    // LOGIN
    on(AuthActions.login, (state, {}) => ({ ...state, isLoading: true })),
    on(AuthActions.loginSuccess, (state, { payload }) => ({ ...state, tokenExpiration: payload.exp, isLoading: false })),
    // REFRESH TOKEN
    on(AuthActions.refreshSuccess, (state, { payload }) => ({ ...state, tokenExpiration: payload.exp })),
    // DISPLAY OTP WINDOW
    on(AuthActions.displayOtp, (state) => ({ ...state, displayOtp: true, tokenOtp: null })),
    // REFRESH TOKEN
    on(AuthActions.setOtp, (state, { token }) => ({ ...state, tokenOtp: token, displayOtp: false })),
    // CLEAR TOKEN
    on(AuthActions.clearOtp, (state) => ({ ...state, tokenOtp: null, displayOtp: false }))
)