import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
// Models
import { SystemModels } from '@models';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
    private API_URI = `https://${environment.apiUrl}/system`;

    constructor(
        private http: HttpClient
    ) { }

    
    public makeiId(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }
}
