import { RouterModule } from '@angular/router';

export const AppRouter = RouterModule.forRoot(
  [
    {
      path: '',
      redirectTo: '/login',
      pathMatch: 'full',
    },
    {
      path: 'login',
      loadChildren: () =>
        import('./auth/auth.module').then((m) => m.AuthModule),
    },
    {
      path: '',
      loadChildren: () =>
        import('@components/layout/layout.module').then((m) => m.LayoutModule),
    },
    {
      path: 'error',
      loadChildren: () =>
        import('./errors/errors.module').then((m) => m.ErrorsModule),
    },
    {
      path: '**',
      redirectTo: '/error/404', //Error 404 - Page not found
    },
  ],
  {
    scrollPositionRestoration: 'enabled',
  }
);
