import { createReducer, on } from '@ngrx/store';
import * as MenuActions from './actions';
import { MenuState } from './state';

export const initialState: MenuState = {
  isLoading: false,
  menuDisplay: false,
  menuOpen: false
};

export const MenuReducers = createReducer(
    initialState,
    on(MenuActions.openMenu, (state) => ({ ...state, menuDisplay: true })),
    on(MenuActions.closeMenu, (state) => ({ ...state, menuOpen: false })),
    on(MenuActions.displayMenu, (state) => ({ ...state, menuOpen: true })),
    on(MenuActions.hiddeMenu, (state) => ({ ...state, menuDisplay: false }))
)
