import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError,  } from 'rxjs/operators';
import { AuthService } from '@services';
import * as AuthActions from './actions';
import { UserActions } from '@state/actions';

@Injectable()
export class AuthEffects {
    validate$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.validate),
        mergeMap(() => this._auth.decodeJWT().pipe(
            map(payload => {
                if(payload){
                    return AuthActions.validateSuccess({ payload: payload })
                }
                return AuthActions.error({error: "Ocurrio un error al decodificar el token"})
            }),
            catchError((err) => of(AuthActions.error({error: err.message})))
          ))
        )
    );

    validateSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.validateSuccess),
        mergeMap(() => this._auth.refresh().pipe(
            map(token => {
                if(!!sessionStorage.getItem('jwt')){
                    sessionStorage.setItem('jwt', token)
                } else {
                    localStorage.setItem('jwt', token)
                }
                return this._auth.decodeJWT()
            }),
            mergeMap(map(payload => {
                if(payload){
                    return AuthActions.refreshSuccess({ payload: payload })
                }
                return AuthActions.error({error: "Ocurrio un error al decodificar el token"})
            })),
            catchError((err) => of(AuthActions.error({error: err.message})))
          ))
        )
    );

    refreshSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.refreshSuccess),
        map(({ payload }) => UserActions.initFromPayload({payload})),
    ));

    login$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.login),
        mergeMap(({ username, password, recaptcha, remember }) => this._auth.login({username, password, recaptcha}).pipe(
            map(token => {
                if(!remember){
                    sessionStorage.setItem('jwt', token)
                } else {
                    localStorage.setItem('jwt', token)
                }
                return this._auth.decodeJWT()
            }),
            mergeMap(map(payload => {
                if(payload){
                    return AuthActions.loginSuccess({ payload: payload })
                }
                return AuthActions.error({error: "Ocurrio un error al decodificar el token"})
            })),
            catchError((err) => of(AuthActions.error({error: err.message})))
          ))
        )
    );

    loginSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        map(({ payload }) => UserActions.initFromPayload({payload})),
    ));

    constructor(
        private actions$: Actions,
        private _auth: AuthService
    ) { }
}