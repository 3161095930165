import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  catchError,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import { environment } from '@env';
import { CoreModels } from '@models';
import { Buffer } from 'buffer';
import { AuthActions } from '@state/actions';
import { CoreState } from '@state/state';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@state/selectors';
import { JwtHelperService } from '@auth0/angular-jwt';
// Models
import { SystemModels } from '@models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private API_URI = `https://${environment.apiUrl}/auth`;
  private admins: Array<string> = [
    '65e12476-a945-48fc-a96b-3c1378c43cd9',
    'cb191228-04ed-4788-9f66-96754cfdefef',
    '',
  ];
  private managers: Array<string> = ['55b2057c-2945-44aa-9530-7497f99c9fd6'];

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<CoreState>
  ) {}

  public login(user: {
    username: string;
    password: string;
    recaptcha: string;
  }): Observable<string> {
    const OPTIONS: Object = {
      responseType: 'text' as const,
    };

    const URL = `${this.API_URI}/login`;
    const BODY = {
      username: user.username,
      password: user.password,
      recaptcha: user.recaptcha,
    };

    return this.http.post<string>(URL, BODY, OPTIONS);
  }

  public refresh(): Observable<string> {
    const OPTIONS: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
        }`,
      }),
      responseType: 'text' as const,
    };
    const URL = `${this.API_URI}/refresh`;

    return this.http.get<string>(URL, OPTIONS);
  }

  public decodeJWT(): Observable<CoreModels.JWTPayload | null> {
    return of(
      sessionStorage.getItem('jwt') || localStorage.getItem('jwt') || ''
    ).pipe(
      map((token) => {
        const parts = token.split('.');
        if (parts.length !== 3) {
          return null;
        }

        const payload: CoreModels.JWTPayload = JSON.parse(
          Buffer.from(parts[1], 'base64').toString('binary')
        );

        return payload;
      })
    );
  }

  public deleteWithOTP(
    url: string,
    options: {
      headers: HttpHeaders;
      responseType?: 'json';
    }
  ): Observable<any> {
    this.store.dispatch(AuthActions.displayOtp());
    return this.store.select(AuthSelectors.selectAuth).pipe(
      filter((auth) => !auth.displayOtp),
      take(1),
      switchMap((auth) => {
        if (auth.tokenOtp == null) {
          throw { message: 'Se cancelo la solicitud del OTP', status: 401 };
        }
        options.headers = options.headers.append('otp-token', auth.tokenOtp);
        return this.http.delete<any>(url, options);
      })
    );
  }

  public logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  public signIn(user: { username: string, password: string, recaptcha: string }): Observable<SystemModels.Validation> {
    const OPTIONS: Object = {
      responseType: 'text' as const,
    };

    const URL = `${this.API_URI}/login`;
    const BODY = {
      username: user.username,
      password: user.password,
      recaptcha: user.recaptcha,
    };

    return this.http.post<string>(URL, BODY, OPTIONS).pipe(
      map((r) => {
        // Registro la session
        localStorage.setItem('jwt', r);
        // Obtengo payload del token
        const helper = new JwtHelperService();
        const decoded: any = helper.decodeToken(r);
        localStorage.setItem('role_id', decoded.role_id);
        const response: SystemModels.Validation = {
          success: true,
          message: 'Login exitoso !!'
        };
        return response;
      }),
      catchError((err) => {
        let DATA: SystemModels.Validation = {
          success: false,
          message: ''
        };

        switch (err.status) {
          case 401: {
            DATA.success = false;
            DATA.message = 'El usuario o la contraseña son invalidos';
            break;
          }
          default: {
            DATA.success = false;
            DATA.message = 'Error desconocido al iniciar sesión, contactar al administrador';
            break;
          }
        }
        throw DATA;
      })
    );
  }

  public isAdmin(): boolean {
    const ROLE_ID = localStorage.getItem('role_id') || '';
    return this.admins.includes(ROLE_ID);
  }

  public isManager(): boolean {
    const ROLE_ID = localStorage.getItem('role_id') || '';
    return this.managers.includes(ROLE_ID);
  }
}
