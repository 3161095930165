import { createSelector } from '@ngrx/store';
import { CoreState } from '../core.state';
import { AuthState } from './state';

export const selectAuth = (state: CoreState) => state.auth;

export const selectTokenExpiration = createSelector(
    selectAuth,
    (state: AuthState) => state.tokenExpiration
);

export const selectTokenOtp = createSelector(
    selectAuth,
    (state: AuthState) => state.tokenOtp
);

export const displayOtp = createSelector(
    selectAuth,
    (state: AuthState) => state.displayOtp
);
