import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
// Enviroment
import { environment } from 'src/environments/environment';
// Models
import { CoreModels, SystemModels } from '@models';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private URL_BASE = `https://${environment.apiUrl}/system`;
  public $loading: BehaviorSubject<boolean>;
  public $notification: BehaviorSubject<SystemModels.Notification>;
  public $modal: BehaviorSubject<SystemModels.Modal>;


  // Moneda

  public addCurrency(currency: SystemModels.Currency): Observable<string> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/currency`;
    return this.http.post<string>(URL, currency, OPTIONS);
  }

  public getCurrency(): Observable<SystemModels.Currency[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/currency`;
    return this.http.get<SystemModels.Currency[]>(URL, OPTIONS);
  }

  public updateCurrency(id: string): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/currency/${id}`;
    return this.http.get<SystemModels.Validation>(URL, OPTIONS);
  }

  public getCurrencyById(id: string): Observable<SystemModels.Currency> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/currency/${id}`;
    return this.http.get<SystemModels.Currency>(URL, OPTIONS);
  }

  public deleteCurrency(id: string): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/currency/${id}`;
    return this.http.get<SystemModels.Validation>(URL, OPTIONS);
  }

  // ZipCode

  public getZipCode(zipcode: string): Observable<SystemModels.ZipCode[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/zipcode/${zipcode}`;
    return this.http.get<SystemModels.ZipCode[]>(URL, OPTIONS);
  }

  public getZipCodeState(): Observable<SystemModels.GroupState[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/zipcode/state`;
    return this.http.get<SystemModels.GroupState[]>(URL, OPTIONS);
  }

  public getZipCodeMunicipality(
    state: string
  ): Observable<SystemModels.GroupMunicipality[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/zipcode/municipality/${state}`;
    return this.http.get<SystemModels.GroupMunicipality[]>(URL, OPTIONS);
  }

  // Zones

  public addZone(
    body: SystemModels.ZoneBasic
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/zone`;
    return this.http.post<SystemModels.Validation>(URL, body, OPTIONS);
  }

  public getZone(
    params?: CoreModels.Params
  ): Observable<SystemModels.ResponseZone> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
      params: new HttpParams()
        .set('user_id', params?.user_id === 'all' ? '' : params?.user_id || '')
        .set('limit', params?.limit || 5)
        .set('offset', params?.offset || 1),
    };

    const URL = `${this.URL_BASE}/zone`;
    return this.http.get<SystemModels.ResponseZone>(URL, OPTIONS);
  }

  public getZoneById(zoneId: string): Observable<SystemModels.Zone> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/zone/${zoneId}`;
    return this.http.get<SystemModels.Zone>(URL, OPTIONS);
  }

  public updateZone(
    zone_id: string,
    body: SystemModels.UpdateZone
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/zone/${zone_id}`;
    return this.http.put<SystemModels.Validation>(URL, body, OPTIONS);
  }

  public deleteZone(zone_id: string): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/zone/${zone_id}`;
    return this.http.delete<SystemModels.Validation>(URL, OPTIONS);
  }

  // Usuarios

  public addUser(body: SystemModels.User): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/user`;
    return this.http.post<SystemModels.Validation>(URL, body, OPTIONS);
  }

  public getUser(
    params?: CoreModels.Params
  ): Observable<SystemModels.ResponseUser> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
      params: new HttpParams()
        .set('user_id', params?.user_id === 'all' ? '' : params?.user_id || '')
        .set('limit', params?.limit || 5)
        .set('offset', params?.offset || 1),
    };

    const URL = `${this.URL_BASE}/user`;
    return this.http.get<SystemModels.ResponseUser>(URL, OPTIONS);
  }

  public getUserList(
    params?: CoreModels.Params
  ): Observable<SystemModels.ResponseUserList> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
      params: new HttpParams()
        .set('user_id', params?.user_id === 'all' ? '' : params?.user_id || '')
        .set('limit', params?.limit || 10)
        .set('offset', params?.offset || 1),
    };
    const URL = `${this.URL_BASE}/user/list`;
    return this.http.get<SystemModels.ResponseUserList>(URL, OPTIONS);
  }

  public updateUser(
    user_id: string,
    body: SystemModels.UserUpdate
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/user/${user_id}`;
    return this.http.put<SystemModels.Validation>(URL, body, OPTIONS);
  }

  public getUserById(user_id: string): Observable<SystemModels.User> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/user/${user_id}`;
    return this.http.get<SystemModels.User>(URL, OPTIONS);
  }

  public getUserByParent(): Observable<SystemModels.User[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/user/parent`;
    return this.http.get<SystemModels.User[]>(URL, OPTIONS);
  }

  public getUserByRole(name: string): Observable<SystemModels.User[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/user/role/${name}`;
    return this.http.get<SystemModels.User[]>(URL, OPTIONS);
  }

  public deleteUser(user_id: string): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/user/${user_id}`;
    return this.http.delete<SystemModels.Validation>(URL, OPTIONS);
  }

  // Roles

  public addRole(
    body: SystemModels.RoleBasic
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/role`;
    return this.http.post<any>(URL, body, OPTIONS);
  }

  public getRole(): Observable<SystemModels.Role[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };

    const URL = `${this.URL_BASE}/role`;
    return this.http.get<SystemModels.Role[]>(URL, OPTIONS);
  }

  public updateRole(
    role_id: string,
    body: SystemModels.RoleBasic
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/role/${role_id}`;
    return this.http.put<SystemModels.Validation>(URL, body, OPTIONS);
  }

  public getRoleById(role_id: string): Observable<SystemModels.Role> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/role/${role_id}`;
    return this.http.get<SystemModels.Role>(URL, OPTIONS);
  }

  public deleteRole(role_id: string): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/role/${role_id}`;
    return this.http.delete<SystemModels.Validation>(URL, OPTIONS);
  }

  // Permission-Role

  public addPermissionRole(
    role_id: string,
    permission_id: string
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/role/${role_id}/permission/${permission_id}`;
    return this.http.post<SystemModels.Validation>(URL, null, OPTIONS);
  }

  public removePermissionRole(
    role_id: string,
    permission_id: string
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/role/${role_id}/permission/${permission_id}`;
    return this.http.delete<SystemModels.Validation>(URL, OPTIONS);
  }

  // Permission Api

  public addPermissionApi(
    body: SystemModels.PermissionApiBasic
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const URL = `${this.URL_BASE}/permission/api`;
    return this.http.post<SystemModels.Validation>(URL, body, OPTIONS);
  }

  public getPermissionApi(): Observable<SystemModels.PermissionApi[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/permission/api`;
    return this.http.get<SystemModels.PermissionApi[]>(URL, OPTIONS);
  }

  public getPermissionApiSelf(): Observable<SystemModels.PermissionApi[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/permission/api`;
    return this.http.get<SystemModels.PermissionApi[]>(URL, OPTIONS);
  }

  public updatePermissionApi(
    permission_id: string,
    body: SystemModels.PermissionApiBasic
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/permission/api/${permission_id}`;
    return this.http.put<SystemModels.Validation>(URL, body, OPTIONS);
  }

  public getPermissionApiById(
    permission_id: string
  ): Observable<SystemModels.PermissionApi> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/permission/api/${permission_id}`;
    return this.http.get<SystemModels.PermissionApi>(URL, OPTIONS);
  }

  public deletePermissionApi(
    permission_id: string
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/permission/api/${permission_id}`;
    return this.http.delete<SystemModels.Validation>(URL, OPTIONS);
  }

  // Permission Web

  public addPermissionWeb(
    body: SystemModels.PermissionWebBasic
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/permission/web`;
    return this.http.post<SystemModels.Validation>(URL, body, OPTIONS);
  }

  public getPermissionWeb(): Observable<SystemModels.PermissionWeb[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/permission/web`;
    return this.http.get<SystemModels.PermissionWeb[]>(URL, OPTIONS);
  }

  public getPermissionWebSelf(): Observable<SystemModels.PermissionWeb[]> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/permission/web`;
    return this.http.get<SystemModels.PermissionWeb[]>(URL, OPTIONS);
  }

  public updatePermissionWeb(
    permission_id: string,
    body: SystemModels.PermissionWebBasic
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/permission/web/${permission_id}`;
    return this.http.put<SystemModels.Validation>(URL, body, OPTIONS);
  }

  public getPermissionWebById(
    permission_id: string
  ): Observable<SystemModels.PermissionWeb> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/permission/web/${permission_id}`;
    return this.http.get<SystemModels.PermissionWeb>(URL, OPTIONS);
  }

  public deletePermissionWeb(
    permission_id: string
  ): Observable<SystemModels.Validation> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/permission/web/${permission_id}`;
    return this.http.delete<SystemModels.Validation>(URL, OPTIONS);
  }

  // Data Dashboard

  public getDataDashboard(): Observable<SystemModels.DataDashboard> {
    const OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('jwt') || localStorage.getItem('jwt')
          }`,
      }),
    };
    const URL = `${this.URL_BASE}/dashboard`;
    return this.http.get<SystemModels.DataDashboard>(URL, OPTIONS);
  }

  // Loading

  public setLoading(value: boolean) {
    this.$loading.next(value);
  }

  // Notification

  public setNotification(data: SystemModels.Notification) {
    this.$notification.next(data);
  }

  public setModal(data: SystemModels.Modal) {
    this.$modal.next(data);
  }

  public getModal(): SystemModels.Modal {
    return this.$modal.getValue();
  }

  constructor(private http: HttpClient) {
    this.$loading = new BehaviorSubject<boolean>(false);
    this.$notification = new BehaviorSubject<SystemModels.Notification>({ show: false, title: '', message: '', type: 'info' });
    this.$modal = new BehaviorSubject<SystemModels.Modal>({ show: false, title: '', message: '', type: 'danger', option: false, component: '' });
  }
}
